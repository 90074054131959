body {
  margin: 0;
  font-family: "ProximaSoft",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Input Telephone Number CSS */
.special-label{
  color:#00000099 !important;
  font-size: 12px !important;
}

.react-tel-input:focus .special-label{
  color:#0cba70 !important;
}

.form-control{
  max-width: -webkit-fill-available;
  height:41px !important
}

.react-tel-input .form-control:focus{
  border-color: #0cba70 !important;
  box-shadow: none !important;
}
.react-tel-input>.form-control:focus>.special-label{
  color:#0cba70 !important;
}


.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
    background: none !important;
}
.swal2-popup .swal2-modal .swal2-show {
  background: red !important;
}

.sendbird-channel-list{width: 330px !important}
.sendbird-channel-header__right-icon{display: none;}
.sendbird-chat-header__right{display: none !important}
.sendbird-message-content__left {display: none !important}
.sendbird-message-content__right {display: none !important}
.sendbird-theme--light .sendbird-text-message-item-body.mouse-hover.outgoing, .sendbird-theme--light .sendbird-text-message-item-body:hover.outgoing{background-color: #0cba70 !important;}
.sendbird-theme--light .sendbird-text-message-item-body.mouse-hover.incoming, .sendbird-theme--light .sendbird-text-message-item-body:hover.incoming {background-color: #f0f0f0 !important;}
.sendbird-message-content__middle__body-container__created-at {display: flex !important;} 
.sendbird-message-status__icon {display: none !important;}
.sendbird-message-input--attach {display: none !important;} 
.sendbird-message-input--voice-message {display: none !important;}
/* .sendbird-message-input--attach, .sendbird-iconbutton {display: none !important;} */
/* .sendbird-icon-attach {display: none !important;} */
/* :hover.sendbird-message-content__left{display: none !important} */

